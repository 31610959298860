.container {
    display: flex;
    flex: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
    padding-top: 32px;
    padding-bottom: 32px;

}