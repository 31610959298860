.location-info {
    display: flex;
    align-items: center;
    font-family: 'Titillium Web', sans-serif;
    font-size: 40px;
}

@media screen and (max-width: 600px) {
    .location-info {
        font-size: 24px;
    }


}