.CurrentWeatherCard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    padding: 1em;
    height: 150px;
    width: 550px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto 1fr auto;
}

.CurrentWeatherTitle {
    font-family: 'Titillium Web', sans-serif;
    color: black;
}

.CurrentWeatherTemperature {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 40px;
    color: black;
    align-self: center;
    justify-self: left;
    grid-column: 1;
}

.CurrentWeatherIcon {
    grid-column: 2;
    grid-row: 1/span 3;
    justify-self: right;
    align-self: center;
}

.CurrentWeatherConditions {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    grid-column: 1/span 1;
    padding-top: -15px;
}

@media screen and (max-width: 750px) {
    .CurrentWeatherCard {
        height: 150px;
        width: 300px;
        padding: 15px;
    }
  
    .CurrentWeatherTemperature {
      font-size: 32px;
    }

    .CurrentWeatherIcon {
        height: 88%;
        width: 95%;
    }
}