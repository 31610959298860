.daily-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    padding: 1em;
    height: 150px;
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.DayTitle {
  font-family: 'Titillium Web', sans-serif;
  color: black;
  align-self: center;
}

.DayIcon {
  width: 75%;
  height: 75%;
  align-self: center;
}

.DayCondition {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  align-self: center;
}

@media screen and (max-width: 600px) {
  .daily-card {
      padding: 15px;
      width: 300px;
      height: 150px;
  }

  .DayIcon {
    width: 36%;
  }
}