.forecast-wrapper {
    display: flex;
    flex: auto;
    flex-direction: row;
    gap: 1em;
    padding: 1em;
}

@media screen and (max-width: 600px) {
    .forecast-wrapper {
        height: 70%;
        padding: 15px;
        flex-direction: column;
    }
}