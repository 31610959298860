.HourlyCard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    padding: 1em;
    height: 150px;
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.HourlyTitle {
    font-family: 'Titillium Web', sans-serif;
    color: black;
}

.HourlyWrapper {
    display: flex;
    justify-self: center;
    justify-content: space-evenly;
    min-height: 126px;
}

.HourWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.HourTime {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: black;
    font-size: 13px;
    padding-top: 10px;

}

.HourTemp {
    font-size: 13px;
}

.HourIcon {
    width: 90%;
}

@media screen and (max-width: 600px) {
  .HourlyCard {
      height: 150px;
      width: 300px;
      padding: 15px;
      flex-direction: column;
  }

  .HourTime {
    font-size: 14px;
  }

  .HourTemp {
    justify-self: center;
    align-self: center;
    font-size: 14px;
  }

  .HourIcon {
    width: 100%;
    height: 40%;
  }
}